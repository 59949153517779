// import http from "@http"
// import env from "@api"
// import request from '@/utils/request'
// 登录方法
import request from '../requestLogin.js'
export function loginFun(data) {
    return new Promise((resolve) => {
        localStorage.setItem('userInfo', JSON.stringify(data))
        resolve()
    })
}

// 退出登录
export function outLoginFun(data) {
    console.log(data);
    return new Promise((resolve) => {
        localStorage.removeItem('userInfo')
        resolve()
    })
}
export function adminLogin(data){
    return request({  //利用我们封装好的request发送请求
        url: '/login/pc/admin',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data: data//向后端传递数据
    })
}
// export function loginFun(phone, password) {
//     return http({
//         url: 'http://localhost:8060/login/pc/admin',
//         method: 'post',
//         data: {
//             phone,
//             password
//         }
//     })
// }